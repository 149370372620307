export function getCookie (name: string): string | undefined {
  if (typeof document === 'undefined' || !name) return

  const value = document?.cookie
  const cookies = value?.split(';')
  const parsed = {}

  cookies?.forEach(cookie => {
    const cookieCrumbles = cookie.split('=')

    if (cookieCrumbles.length === 2) {
      const key = cookieCrumbles[0].trim()
      const val = cookieCrumbles[1].trim()

      if (!Object.keys(parsed).includes(key)) {
        Object.assign(parsed, { [`${key}`]: val })
      }
    }
  })

  return parsed[name as keyof typeof parsed] || undefined
}
