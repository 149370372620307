import { getCookie } from './get-cookie'

export function deleteCookie (name: string): boolean {
  if (typeof document === 'undefined' || !name) return false
  if (!getCookie(name)) return false

  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`

  return true
}
