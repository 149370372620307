export function getVertical (vertical: string | undefined): string {
  switch (vertical) {
    case 'consumerLoan':
      return 'cl'
    case 'SME':
      return 'sme'
    case 'Mortgage':
      return 'mortgage'
    default:
      return ''
  }
}
