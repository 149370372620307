import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { detectIncognito } from 'detectincognitojs'
import { getUrlSearchParams } from './get-url-search-params'
import { log } from './log'

interface Params {
  gclid: string
  utm_source: string
}

export async function gclidDebug () {
  const params = getUrlSearchParams() as Params
  const path = getServerState('path')

  if (!params || params?.utm_source !== 'google' || params.gclid) return

  const incognito = await detectIncognito()

  log('warning', 'gclid', {
    path,
    params,
    rawParams: window.location.search,
    browser: incognito.browserName,
    incognito: incognito.isPrivate
  })
}
