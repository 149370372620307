import { deleteCookie } from './delete-cookie'
import { getCookie } from './get-cookie'
import { getCookieSettings } from './get-cookie-settings'

interface SetCookieParameters {
  name: string
  value: any
  lifeTimeInDays?: number | null
  type?: 'op_user_id' | 'access_token' | null
}

export function setCookie ({ name, value, lifeTimeInDays = null, type = null }: SetCookieParameters): boolean {
  if (typeof document === 'undefined' || !name) return false
  if (getCookie(name)) deleteCookie(name)

  const cookieValues = []
  const cookieSettings = getCookieSettings(type)

  cookieValues.push(`${name}=${value};`)

  if (lifeTimeInDays) {
    const maxAge = 90000000 * lifeTimeInDays
    cookieValues.push(`Max-Age=${maxAge};`)
  } else {
    cookieValues.push(`Max-Age=${cookieSettings.maxAge};`)
  }

  cookieValues.push('secure;')
  cookieValues.push('sameSite=none;')
  cookieValues.push('path=/;')

  document.cookie = cookieValues.join(' ')

  return true
}
