interface CookieSettings {
  maxAge: number
  httpOnly: boolean
}

export function getCookieSettings (type: 'op_user_id' | 'access_token' | null = null): CookieSettings {
  const settings = {
    default: { maxAge: 90000000 * 30, httpOnly: false },
    op_user_id: { maxAge: 90000000 * 60, httpOnly: false },
    access_token: { maxAge: 90000000 * 30, httpOnly: true }
  }

  return type
    ? settings[type] || settings.default
    : settings.default
}
