import { request } from '../../utils/request'

export async function log (type: string, msg: string, data: object) {
  if (!type || !msg) return

  return await request({
    method: 'POST',
    url: '/app-pages/log',
    body: {
      type,
      msg,
      data
    }
  })
}
