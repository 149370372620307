import type { Page } from '@ocp-zmarta/content-sdk'

interface Input {
  market: string
  data: Page
  config?: {
    locales: {
      options: string[]
    }
  }
}

export function defineSlugs ({ market, data, config }: Input) {
  if (!market || !data || !config) return

  try {
    const options = config.locales?.options
    if (options?.length <= 1) return

    const hreflangs = data?.seo?.hreflangs
    if (!hreflangs?.length) return

    const slugs = {}

    hreflangs.forEach(item => {
      const key = item.hreflang?.split('-')?.[0]
      const url = item.href && new URL(item.href)

      if (key && url) Object.assign(slugs, { [`${key}`]: url.pathname })
    })

    return slugs
  } catch {
    return undefined
  }
}
