export function getUrlSearchParams () {
  if (typeof window === 'undefined') return {}
  if (!window.location.search) return {}

  const params = window.location.search.substring(1).split('&')
  const query = {}

  params && params
    .forEach(item => {
      const [key, value] = item.split('=')
      Object.assign(query, { [`${key}`]: value })
    })

  return query
}
