import { UnexpectedError } from '@ocp-zmarta/zmarta-errors'

interface Request {
  method: string
  url: string
  headers?: object
  body?: object | undefined
  json?: boolean
}

export async function request ({ method = 'GET', url, headers = {}, body, json = true }: Request) {
  if (!url) return

  try {
    let response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: body && JSON.stringify(body)
    })

    response = json
      ? await response?.json()
      : await response?.text()

    return response
  } catch (error) {
    throw new UnexpectedError('Oops! Something went wrong!', {
      privateCtx: {
        name: 'REQUEST_ERROR',
        message: 'could not complete request',
        location: 'utils/request',
        url,
        error
      }
    })
  }
}
